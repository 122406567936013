import React from "react";
import { Result, Button } from "antd";
import useUser from "../lib/useUser";
import { useFetchLocalStorage } from "../lib/useFetchLocalStorage";

const NotFoundPage = () => {
  useFetchLocalStorage();
  useUser({ redirectTo: "/login" });

  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={<Button type="primary">Back Home</Button>}
    />
  );
};

export default NotFoundPage;
